import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { ENotificationRelatedObjectType } from "../../enums/notification-related-object-type.enum";
import { Router } from "@angular/router";
import { IGenericKeyValue } from "../../models/exam/general.model";
import { toQueryString } from "../../helpers/query-string.helper";

@Injectable()
export class NotificationService {
  private apiPrefix = '/client/notifications';

  constructor(
    private api: ApiService,
    private router: Router,
  ) {
  }

  subscribeToken(token: string) {
    return this.api.post(`${this.apiPrefix}/subscribe`, {subscriptionId: token});
  }

  getUnreadNotificationCount() {
    return this.api.get(`${this.apiPrefix}/unread-count?t=${new Date().getTime()}`);
  }

  getNotifications(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}t=${new Date().getTime()}`);
  }

  readNotifications(data: object) {
    return this.api.patch(`${this.apiPrefix}/read`, data);
  }

  markAllNotificationAsRead() {
    return this.api.patch(`${this.apiPrefix}/mark-all-read`, {});
  }

  routeNotification(relatedObjectType?: ENotificationRelatedObjectType | null, relatedObjectId?: number | null, queryParams: IGenericKeyValue = {}) {
    let queryString = toQueryString(queryParams);
    queryString = queryString ? `?${queryString}` : '';

    if ((relatedObjectType === ENotificationRelatedObjectType.Video) && relatedObjectId) {
      this.router.navigateByUrl('/dashboard/videos/detail/' + relatedObjectId + queryString);
    } else if (relatedObjectType == ENotificationRelatedObjectType.Document) {
      this.router.navigateByUrl('/dashboard/documents' + queryString);
    } else if ((relatedObjectType == ENotificationRelatedObjectType.LiveStream) && relatedObjectId) {
      this.router.navigateByUrl('/dashboard/videos/live/' + relatedObjectId + queryString);
    } else if ((relatedObjectType == ENotificationRelatedObjectType.Blog) && relatedObjectId) {
      this.router.navigateByUrl('/dashboard/blog/' + relatedObjectId + queryString);
    }

    // TODO add blog and comments
  }
}
